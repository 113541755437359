import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

import { StorageService } from 'salesonepro';

@Component({
  selector: 'app-unauthorized',
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {
  constructor(
    private router: Router,
    private storage: StorageService,
  ) { }

  ngOnInit(): void { }

  routeToDefaultPath() {
    if (this.storage.retrieve('defaultRoute')) {
      const defaultRoute = this.storage.retrieve('defaultRoute');
      this.router.navigate([defaultRoute]);
    } else {
      this.router.navigate(['/', 'dashboard']);
    }
  }
}
