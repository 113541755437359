import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';

import { User, AuthService, StorageService } from 'salesonepro';

@Component({
  selector: 'app-topnav',
  templateUrl: 'topnav.component.html',
  styleUrls: ['./topnav.component.scss']
})
export class TopNavComponent implements OnInit {
  currentUser: User;
  mainNavigation: any = [];
  userEmail: string;

  loadingMainNav: boolean;
  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        this.userEmail = userData['email'];
      }
    );

    this.loadingMainNav = true;
    if (this.storage.retrieve('navigation', 'session')) {
      this.loadingMainNav = false;
      this.mainNavigation = this.storage.retrieve('navigation', 'session');
    } else {
      this.storage.observe('leftNavigation', 'session')
      .subscribe((value) => {
        setTimeout(() => {
          this.loadingMainNav = false;
          this.mainNavigation = this.storage.retrieve('navigation', 'session');
        });
      }, error => {
        this.loadingMainNav = false;
      });
    }
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigateByUrl('/login').then(() => {
      this.router.navigate(['/', 'login']);
    });
  }
}
