<app-header></app-header>
<!-- <app-topnav></app-topnav> -->
<div class="app-body">
  <app-sidebar></app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <app-breadcrumbs></app-breadcrumbs>
    <div class="container-fluid">
      <router-outlet><lib-salesonepro-spinner  type="route"></lib-salesonepro-spinner></router-outlet>
    </div><!-- /.conainer-fluid -->
    <app-footer></app-footer>
  </main>
 
</div>

<div class="confirmation-overlay"></div>
<div class="overlay"></div>
<!--<app-aside></app-aside>-->
