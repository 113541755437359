import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthComponent, NoAuthGuard, AuthGuard, AccessGuard, DefaultRouteGuard } from 'salesonepro';

import {
  LayoutComponent,
  SimpleLayoutComponent,
  NotfoundComponent,
  UnauthorizedComponent
} from '@theme/layout/index';

import { StampsCallbackComponent } from './stampscallback.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [ DefaultRouteGuard ],
    component: SimpleLayoutComponent,
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [ AuthGuard ],
    children: [
      {
        path: 'dashboard',
        component: SimpleLayoutComponent,
      },
      {
        path: 'sales',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/sales/sales.module').then(m => m.SalesModule),
        data: {
          acl: 'sales::sales'
        }
      },
      {
        path: 'hr',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/hr/hr.module').then(m => m.HrModule),
        data: {
          acl: 'hr::hr'
        }
      },
      {
        path: 'reports',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/reports/reports.module').then(m => m.ReportsModule),
        data: {
          acl: 'reports::reports'
        }
      },
      {
        path: 'ecommerce',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/ecommerce/ecommerce.module').then(m => m.EcommerceModule),
        data: {
          acl: 'store::store'
        }
      },
      {
        path: 'production',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/production/production.module').then(m => m.ProductionModule),
        data: {
          acl: 'production::production'
        }
      },
      {
        path: 'settings',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/settings/settings.module').then(m => m.SettingsModule),
        data: {
          acl: 'settings::settings'
        }
      },
      {
        path: 'accounting',
        canActivate: [AccessGuard],
        loadChildren: () => import('./core/accounting/accounting.module').then(m => m.AccountingModule),
        data: {
          acl: 'accounting::accounting'
        }
      }
    ]
  },
  {
    path: 'login',
    component: AuthComponent,
    canActivate: [NoAuthGuard]
  },
  {
    path: 'stampscallback',
    component: StampsCallbackComponent,
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
