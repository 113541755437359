import { environment } from 'environments/environment';

export const appConfig = {
  projectName: 'dyesubpro',
  tableRowLimit: environment.tableRowLimit,
  salesSettings: {
    supportedOrderType: ['custom']
  },
  settingsModuleConfig: {
    storeSettings: {
      supportedSettings: ['basic', 'email', 'pdf', 'sales', 'shipping_providers', 'shipment_label']
    }
  },
  salesOneProConfig: {
    showMaskGuide: false
  }
};