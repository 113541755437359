<div class="sidebar">
  <nav class="sidebar-nav" [appSpinner]="loadingNavigation">
    <div class="sidebar-filter-wrap" *ngIf="currentUrl.includes('ecommerce')">
      <span class="font-xs">Select Store</span>
      <select class="form-control" (change)="onChangeTable($event)">
        <option *ngFor="let item of stores" [value]="item.id" selected="{{item.id === storeId ? 'selected' : ''}}">{{item.name}}</option>
      </select>
    </div>
    <ul class="nav" *ngIf="!loadingNavigation && items.length > 0">
      <li *ngFor="let menuItem of items" class="nav-item" [ngClass]="{'nav-dropdown': (menuItem && !isEmptyObject(menuItem['children'])), 'active': breadcrumbList.includes(menuItem.slug)}"
        routerLinkActive="open" appNavDropdown>
        <a *ngIf="menuItem && isEmptyObject(menuItem['children'])" class="nav-link" routerLinkActive="active" [routerLink]="['/'+parentRoute+'/'+menuItem.slug]">
          <lib-salesonepro-icon name="{{menuItem.icon}}" *ngIf="menuItem.icon"></lib-salesonepro-icon> {{menuItem.title}}
        </a>
        <a *ngIf="menuItem && !isEmptyObject(menuItem['children'])" class="nav-link nav-dropdown-toggle" href="#" appNavDropdownToggle>
          <lib-salesonepro-icon name="{{menuItem.icon}}" *ngIf="menuItem.icon"></lib-salesonepro-icon> {{menuItem.title}}
        </a>
        <ul class="nav-dropdown-items" *ngIf="menuItem && !isEmptyObject(menuItem['children'])">
          <li class="nav-item" *ngFor="let child of menuItem.children['left']">
            <a class="nav-link" [ngClass]="{'open': (breadcrumbList.includes(child.slug) || breadcrumbList.includes(child.title) || breadcrumbList.includes(child.title.toLowerCase))}" routerLinkActive="active" [routerLink]="['/'+parentRoute+'/'+menuItem.slug+'/'+child.slug]">
              <i class="icon-puzzle"></i> {{child.title}}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</div>