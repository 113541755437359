import { Component, ViewContainerRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


import { AuthService } from 'salesonepro';
import { ServiceWorkerService } from './sw.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor (
    private authService: AuthService,
    private translate: TranslateService,
    private sWService: ServiceWorkerService,
    public vcr: ViewContainerRef,
  ) {
    this.translate.addLangs(['en', 'fr']);
    const browserLang = translate.getBrowserLang();
    this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }

  ngOnInit() {
    this.authService.populate();
    this.sWService.checkForUpdates();
  }
}
