import {filter} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';

import { AuthService, StorageService } from 'salesonepro';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  navigations: any = [];
  items: any = [];
  parentRoute = '';
  breadcrumbList: any = [];
  activeOrderPage = '';
  loadingNavigation: boolean;

  storeId: any;
  stores: any;
  currentUrl: string;

  allowedOrderList = [
    'blank-orders',
    'orders',
    'custom-orders',
    'subcontractor-orders'
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storage: StorageService
  ) {
    this.buildItems();
  }

  ngOnInit() {
    this.router.events.pipe(
    filter(event => event instanceof NavigationEnd))
    .subscribe(e => {
      if (e instanceof NavigationEnd) {
        if (e.url !== '/login') {
          this.buildItems();
        }
      }
    });
    this.route.params.subscribe(params => {
      this.findRouteData();
    });
    this.setEcommerce();
  }

  buildItems() {
    this.currentUrl = this.router.url;
    const url: string = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url.join('') : '';
    this.parentRoute = url;
    this.findRouteData();
    this.loadingNavigation = true;
    if (this.storage.retrieve('leftNavigation', 'session')) {
      this.loadingNavigation = false;
      const leftMenu = this.storage.retrieve('leftNavigation', 'session');
      this.items = typeof leftMenu[url] !== 'undefined' ? leftMenu[url] : [];
    } else {
      this.storage.observe('leftNavigation', 'session')
      .subscribe((value) => {
        setTimeout(() => {
          this.loadingNavigation = false;
          const leftMenu = this.storage.retrieve('leftNavigation', 'session');
          if (leftMenu) {
            this.items = typeof leftMenu[url] !== 'undefined' ? leftMenu[url] : [];
          }
        });
      }, error => {
        this.loadingNavigation = false;
      });
    }
  }

  findRouteData() {
    this.breadcrumbList = [];
    let currentRoute = this.route.root,
    url = '';
    do {
      const childrenRoutes = currentRoute.children;
      currentRoute = null;
      childrenRoutes.forEach(routes => {
        if (routes.outlet === 'primary') {
          const routeSnapshot = routes.snapshot;
          if (routeSnapshot && routeSnapshot.url) {
            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
          }
          // tslint:disable-next-line:max-line-length
          if (routeSnapshot && routeSnapshot.data && routes.snapshot.data.breadcrumb !== undefined && routes.snapshot.data.breadcrumb !== '') {
            if ((this.allowedOrderList.includes(routes.snapshot.data.parent))
            // tslint:disable-next-line:max-line-length
            && routes.snapshot.data.breadcrumb === 'Details' && this.storage.retrieve('activeOrderPage') && this.storage.retrieve('activeOrderPage') !== '') {
              const page = this.storage.retrieve('activeOrderPage').replace(/-/g, ' ').toLowerCase();
              this.breadcrumbList.push(page);
              this.breadcrumbList.push(this.storage.retrieve('activeOrderPage'));
            }
            if (routes.snapshot.data.parent === 'products') {
              this.breadcrumbList.push(routes.snapshot.data.parent);
            }
            const breadcrumb = routes.snapshot.data.breadcrumb.toLowerCase();
            this.breadcrumbList.push(breadcrumb);
            const hyphenatedBreadcrumb = breadcrumb.replace(' ', '-').toLowerCase();
            this.breadcrumbList.push(hyphenatedBreadcrumb);
          }
          currentRoute = routes;
        }
      });
    } while (currentRoute);
  }

  isEmptyObject(obj) {
    return (obj && (Object.keys(obj).length === 0));
  }

  onChangeTable(event) {
    if (event.target.value) {
      this.storeId = event.target.value;
      this.storage.store('current_store_id', event.target.value);
    }
  }

  setEcommerce() {
    if (this.storage.retrieve('current_store_id')) {
      this.storeId = this.storage.retrieve('current_store_id');
      if (this.storage.retrieve('stores')) {
        this.stores = this.storage.retrieve('stores');
      }
    } else {
      if (this.storage.retrieve('stores')) {
        this.stores = this.storage.retrieve('stores');
        if (this.stores.length > 0) {
          this.storeId = this.stores[0].id;
          this.storage.store('storeId',  this.storeId, 'session');
        }
      }
    }
  }

}
