<div class="horizontal-menu-fixed">
  <ul class="nav float-left bg-white py-1 pl-3 user-nav">
    <li class="nav-item dropdown" ngbDropdown>
      <a href class="nav-link dropdown-toggle p-0" ngbDropdownToggle (click)="false">
        <img src="{{currentUser.avatar_thumbnail ? currentUser.avatar_thumbnail : 'assets/img/user_avatar.jpg'}}" class="img-avatar float-left">
        <div class="name float-left ml-2">
          <span class="d-md-down-none font-weight-normal">{{currentUser['name']}}</span>
          <small class="d-block" *ngIf="userEmail">{{userEmail}}</small>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="simple-dropdown">
        <div class="dropdown-header d-none"><strong>{{'TOPNAVMENU.Account'|translate}}</strong></div>
        <a class="dropdown-item  d-none" href="#"><i class="fa fa-envelope-o"></i>{{'TOPNAVMENU.Messages'|translate}}<span
          class="badge badge-primary">42</span></a>
        <a class="dropdown-item  d-none" href="#"><i class="fa fa-tasks"></i>{{'TOPNAVMENU.Tasks'|translate}}<span class="badge badge-primary">42</span></a>
        <div class="dropdown-header  d-none"><strong>{{'TOPNAVMENU.Settings'|translate}}</strong></div>
        <a class="dropdown-item  d-none" href="#"><i class="fa fa-user"></i>{{'TOPNAVMENU.Profile'|translate}}</a>
        <a class="dropdown-item  d-none" href="#"><i class="fa fa-wrench"></i>{{'TOPNAVMENU.Settings'|translate}}</a>
        <div class="divider"></div>
        <a class="dropdown-item" href="#" (click)="logout()"><i class="fa fa-lock"></i>{{'TOPNAVMENU.Logout'|translate}}</a>
      </div>
    </li>
  </ul>
  <div class="nav-item dropdown top-nav-wrap" ngbDropdown>
    <a href class="text-dark nav-link dropdown-toggle d-md-block d-lg-none p-0 mr-2 mt-2 float-right font-lg dropdown-toggle"
      ngbDropdownToggle (click)="false">
      Menu
    </a>
    <div class="dropdown-menu d-lg-flex dropdown-menu-right m-0 position-relative border-0" [appSpinner]="loadingMainNav">
      <ul class="main top-nav navbar-nav ml-0">
        <li class="nav-item px-4" routerLinkActive="active">
          <a class="nav-link" [routerLink]="['/dashboard']"><i class="fa fa-home home-icon"></i></a>
        </li>
        <ng-container *ngIf="!loadingMainNav">
          <li class="nav-item px-4" *ngFor="let menu of mainNavigation" routerLinkActive="active">
            <a class="nav-link" [routerLink]="['/' +menu.slug]"> {{ menu.title }}</a>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</div>