<header class="app-header navbar">
  <button class="navbar-toggler d-lg-none" type="button" appMobileSidebarToggler>&#9776;</button>
  <a class="navbar-brand cursor-pointer" (click)="routeToDefaultPath()"></a>
  <button class="navbar-toggler d-md-down-none" type="button" appSidebarToggler>&#9776;</button>


  <div class="nav navbar-nav ml-0 top-nav-wrap" ngbDropdown>
    <a href
      class="text-dark nav-link dropdown-toggle d-md-block d-lg-none p-0 mr-2 mt-2 float-right font-lg dropdown-toggle"
      ngbDropdownToggle (click)="false">
      Menu
      <lib-salesonepro-icon name="caret-down" class="ml-1"></lib-salesonepro-icon>
    </a>
    <div class="dropdown-menu d-lg-flex dropdown-menu-right m-0 border-0 bg-transparent">
      <ul class="main top-nav navbar-nav ml-0" *ngIf="!loadingMainNav">
        <li class="nav-item" *ngFor="let menu of mainNavigation" routerLinkActive="active">
          <a class="nav-link" (click)="onRouteClick(menu)" [routerLink]="['/' +  menu.slug]">{{menu.title}}</a>
        </li>
      </ul>
    </div>
  </div>

  <ul class="nav navbar-nav ml-auto mr-4 user-nav">
    <li class="nav-item" (click)="handleSearch()">
      <a class="nav-link cursor-pointer">
        <lib-salesonepro-icon name="search"></lib-salesonepro-icon>
      </a>
    </li>
    <li class="nav-item dropdown d-md-down-none" ngbDropdown>
      <a class="nav-link cursor-pointer" ngbDropdownToggle (click)="markAllAsViewed()">
        <lib-salesonepro-icon name="bell-o"></lib-salesonepro-icon><span class="badge badge-pill badge-danger"
          *ngIf="notificationCount > 0">{{notificationCount}}</span>
      </a>
      <div aria-labelledby="simple-dropdown" class="dropdown-menu dropdown-menu-right dropdown-menu-lg">
        <div class="beeperNub text-primary">
          <lib-salesonepro-icon name="caret-up"></lib-salesonepro-icon>
        </div>
        <div class="text-left border-bottom p-2">
          <strong>Notifications</strong>
          <a class="cursor-pointer float-right text-primary" *ngIf="notificationList.length > 0"
            (click)="markAllAsRead()">
            <small>Mark all as read</small>
          </a>
        </div>
        <div class="custom-scroll" [appSpinner]="updateNotification || loadingNotifications">
          <div class="div-wrapper-scroll-y notification-list" *ngIf="notificationList.length > 0">
            <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="5" (scrolled)="onScrollDown()">
              <ng-container *ngFor="let msg of notificationList">
                <a class="dropdown-item px-2 font-sm" [ngClass]="{'cursor-pointer': msg.link}"
                  (click)="viewDetails(msg)">
                  <div class="font-13 text-muted">{{msg.message}} </div>
                </a>
              </ng-container>
            </div>
          </div>
          <span class="pl-2 font-sm" *ngIf="notificationList.length === 0">You have no new notifications.</span>
        </div>
      </div>
    </li>
    <li class="nav-item dropdown" ngbDropdown>
      <a href class="nav-link dropdown-toggle p-0" ngbDropdownToggle (click)="false">
        <img *ngIf="currentUser.avatar_thumbnail; else noAvatar" [src]="currentUser.avatar_thumbnail"
          class="img-avatar float-left">
        <ng-template #noAvatar>
          <div class="img-avatar position-relative text-acronym float-left">
            <span class="name-text">{{userAcronym}}</span>
          </div>
        </ng-template>
        <div class="name float-left d-flex flex-column">
          <div class="user-name-wrap">
            <span class="d-md-down-none font-weight-normal float-left user-name">{{currentUser['name']}} </span>
            <lib-salesonepro-icon name="caret-down" class="ml-1"></lib-salesonepro-icon>
          </div>
          <div class="d-block">
            <small class="d-md-down-none user-name" *ngIf="userEmail">{{userEmail}}</small>
          </div>
        </div>
      </a>
      <div class="dropdown-menu dropdown-menu-right" aria-labelledby="simple-dropdown">
        <a class="dropdown-item cursor-pointer" (click)="logout()"><i
            class="fa fa-lock"></i>{{'Logout'|translate}}</a>
      </div>
    </li>
  </ul>
  <div class="header-wrapper row m-0" *ngIf="showSearch">
    <form class="form-inline search-full open" [appSpinner]="loadingOrders">
      <div class="form-group w-100">
        <input type="text" autocomplete="off" placeholder="Search.." [formControl]="search"
          class="form-control-plaintext">
        <lib-salesonepro-icon name="times" class="ml-1 close-search" (click)="handleSearch()">
        </lib-salesonepro-icon>
        <div class="quick-search-wrapper p-3 custom-scroll" [appSpinner]="paginatedLoading" *ngIf="orders.length > 0">
          <div class="search-wrapper-scroll-y" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="5"
            [scrollWindow]="false" [alwaysCallback]="true" (scrolled)="onOrderScrollDown()">
            <div class="quick-search-result">
              <div class="mb-10">
                <div class="d-flex align-items-center flex-grow-1 mb-2" *ngFor="let order of orders">
                  <div class="d-flex flex-column mt-2 mb-2">
                    <span>
                      <b>{{order['order_status']['type'] === 'quote' ? 'Quote' : 'Order'}} #:</b>
                      <a routerLink="/sales/orders/open-orders/details/{{order.order_number}}" class="font-weight-bold"
                        (click)="handleSearch()">
                        {{order['order_number']}}
                      </a>
                    </span>
                    <span class="font-sm"
                    [ngClass]="{'text-muted': order['order_status']['group'] !== 'lost', 'text-danger': order['order_status']['group'] === 'lost'}"
                      *ngIf="order['order_status']">
                      <b>Status:</b> {{order['order_status']['name']}}
                    </span>
                    <span class="font-sm text-muted" *ngIf="order['order_date']">
                      <b>Order Date:</b> {{order['order_date']}}
                    </span>
                    <span class="font-sm text-muted" *ngIf="order['customer_po']">
                      <b>Customer PO:</b> {{order['customer_po']}}
                    </span>
                    <span class="font-sm text-muted" *ngIf="order['contact']">
                      <b>Customer:</b> {{order['contact']['full_name']}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>

</header>