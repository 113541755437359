import { Component, OnInit, ElementRef } from '@angular/core';
import { Router} from '@angular/router';
import { FormControl } from '@angular/forms';
import { HttpParams } from '@angular/common/http';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';

import { User, AuthService, StorageService, SocketService, ToasterService, SalesOneProSharedService } from 'salesonepro';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  currentUser: User;
  mainNavigation: any = [];
  userEmail: string;
  userAcronym: string;

  loadingMainNav: boolean;

  start: Number = 1;
  params: HttpParams;
  loadingNotifications: boolean;
  notifications = {
    count: 0,
    results: [],
    unviewed_count: 0,
    next: '',
    previous: '',
  };
  notificationList: Array<any> = [];

  updateNotification: boolean;
  notificationCount: 0;

  showSearch: boolean;
  search = new FormControl();
  searchParams: HttpParams;
  loadingOrders: boolean;
  allOrders = {
    'count': 0,
    'results': [],
    'next': '',
  };
  orders = [];
  page = 1;
  orderLimit = '25';
  paginatedLoading: boolean;

  constructor(
    private router: Router,
    private authService: AuthService,
    private storage: StorageService,
    private socketService: SocketService,
    private sharedService: SalesOneProSharedService,
    private toastr: ToasterService,
    private el: ElementRef,
  ) {
    this.socketService.messages.subscribe(msg => {
      this.toastr.info(msg['message'], 'Notification', {
        disableTimeOut: true
      });
      this.getNotifications();
    });

    this.setOrderParams();

    this.search.valueChanges.pipe(
      debounceTime(600),
      distinctUntilChanged()
    ).subscribe(searchText => {
      this.searchParams = this.searchParams.delete('search');
      if (searchText !== '') {
        this.setOrderParams();
        this.searchParams = this.searchParams.append('search', searchText);
        this.getOrders();
      } else {
        this.setOrderParams();
      }
    });

    this.params = new HttpParams();
    this.params = this.params.delete('limit');
    this.params = this.params.append('limit', '15');
    this.params = this.params.delete('read');
    this.params = this.params.append('read', 'false');
  }

  ngOnInit() {
    this.authService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
        this.userEmail = userData['email'];
        const matches = userData['name'].match(/\b(\w)/g);
        this.userAcronym = matches.join('');
      }
    );

    this.loadingMainNav = true;
    if (this.storage.retrieve('navigation', 'session')) {
      this.loadingMainNav = false;
      this.mainNavigation = this.storage.retrieve('navigation', 'session');
    } else {
      this.storage.observe('leftNavigation', 'session')
      .subscribe((value) => {
        setTimeout(() => {
          this.loadingMainNav = false;
          this.mainNavigation = this.storage.retrieve('navigation', 'session');
        });
      }, error => {
        this.loadingMainNav = false;
      });
    }

    this.notificationList = [];
    this.getNotifications();
  }

  logout() {
    this.authService.purgeAuth();
    this.router.navigate(['/', 'login']);
  }

  onRouteClick(item) {
    let redirectRoute = item['slug'];
    if (item['children']['left'] && item['children']['left'][0]) {
      const leftMainRoute = item['children']['left'][0];
      redirectRoute += '/' + leftMainRoute['slug'];
      if (leftMainRoute['children'] && leftMainRoute['children']['left'] && leftMainRoute['children']['left'][0]) {
        redirectRoute += '/' + leftMainRoute['children']['left'][0]['slug'];
      }
    }
    this.router.navigateByUrl(redirectRoute);
  }


  routeToDefaultPath() {
    if (this.storage.retrieve('defaultRoute')) {
      const defaultRoute = this.storage.retrieve('defaultRoute');
      this.router.navigate([defaultRoute]);
    } else {
      this.router.navigate(['/', 'dashboard']);
    }
  }

  handleSearch() {
    this.showSearch = !this.showSearch;
    this.setOrderParams();
    this.search.setValue('');
    const target = this.el.nativeElement.querySelector('.form-control-plaintext');
    if (target) {
      target.focus();
    }
  }

  setOrderParams() {
    this.orders = [];
    this.page = 1;
    this.searchParams = new HttpParams().append('view', 'all');
    this.searchParams = this.searchParams.delete('limit');
    this.searchParams = this.searchParams.append('limit', this.orderLimit);
    this.searchParams = this.searchParams.delete('ordering');
    this.searchParams = this.searchParams.append('ordering', '-order_date');
  }

  getOrders(paginated?) {
    if (paginated) {
      this.paginatedLoading = true;
    } else {
      this.loadingOrders = true;
    }
    this.sharedService.getOrderList('all__all__all_item', this.searchParams).subscribe(response => {
      if (this.search.value !== '') {
        this.allOrders = response;
        if (paginated) {
          this.orders = this.orders.concat(response['results']);
        } else {
          this.orders = response['results'];
        }
      } else {
        this.orders = [];
      }
      this.paginatedLoading = false;
      this.loadingOrders = false;
    }, error => {
      this.paginatedLoading = false;
      this.loadingOrders = false;
    });
  }

  onOrderScrollDown() {
    if (this.allOrders.next && this.allOrders.next !== '' && !this.loadingOrders && !this.paginatedLoading) {
      this.page += 1;
      const start = (this.page - 1) * parseInt(this.orderLimit, 10);
      this.searchParams = this.searchParams.delete('offset');
      this.searchParams = this.searchParams.append('offset', start.toString());
      this.getOrders(true);
    }
  }

  getNotifications() {
    this.loadingNotifications = true;
    this.authService.getNotifications(this.params).subscribe(response => {
      this.notifications = response;
      response.results.forEach(element => {
        this.addToNotificationList(element);
      });
      this.loadingNotifications = false;
      this.notificationCount = response.unviewed_count;
    }, error => {
      this.loadingNotifications = false;
    });
  }

  addToNotificationList(item) {
    const message = item.message;
    let link = false;
    if (item.type === 'order_status_due_date_update') {
      link = true;
    }
    const tmp = {
      message: message,
      data: item['data'][item.type],
      type: item.type,
      link: link
    };
    this.notificationList.push(tmp);
  }

  onScrollDown() {
    if (this.notifications.next && this.notifications.next !== '') {
      const tmp = this.notifications.next.split('?');
      if (tmp[1]) {
        const params = tmp[1].split('&');
        params.forEach(element => {
          const data = element.split('=');
          this.params = this.params.delete(data[0]);
          this.params = this.params.append(data[0], data[1]);
        });
        this.getNotifications();
      }
    }
  }

  viewDetails(item) {
    if (item.link) {
      const id = item.data.order.order_number;
      if (item.data.order.order_status__type === 'quote') {
        this.router.navigate(['./sales/quotes/details/', id]);
      } else {
        this.router.navigate(['./sales/orders/details/', id]);
      }
    }
  }

  markAllAsRead() {
    this.updateNotification = true;
    this.authService.updateNotifications('read').subscribe(response => {
      this.notificationList = [];
      this.updateNotification = false;
      this.getNotifications();
    });
  }

  markAllAsViewed() {
    if (this.notificationCount > 0) {
      this.authService.updateNotifications('viewed').subscribe(response => {
        this.notificationCount = 0;
      });
    }
  }
}
